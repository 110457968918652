import * as React from "react";
import { createRoot } from "react-dom/client";

import { detect } from "detect-browser";
import { isNil } from "lodash";
import { SplashScreen } from "./splash";
import { UnsupportedBrowser } from "./unsupportedBrowser";

/**
 * CSS loading order should be like:
 * 1. 3rd party libraries
 * 2. MIL libraries (order can matter)
 * 3. Style from this project
 */

import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/table/lib/css/table.css"; // TODO: Maybe this is too heavy to load now
// The import of the datetime css is complicated
// This doesn't work for now: `import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";`
import "@blueprintjs/datetime2/node_modules/@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/datetime2/lib/css/blueprint-datetime2.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";

// Load all required CSS now instead of in app.tsx to avoid visual glitches
import "~/assets/image/favicon.ico";
import "react-cover-page/themes/default.css";
import "react-flexible-workbench/themes/dark.css";
import "@fontsource/roboto/latin.css"; // The latin.css contains all of the available font weights.
import "@fontsource/inconsolata/latin.css"; // The latin.css contains all of the available font weights.

import "@mil/formatters/lib/src/assets/css/formatters.css";
import "@mil/blueprint-components/lib/src/assets/less/blueprintComponents.less";
import "@mil/blueprint-form-components/lib/src/assets/less/blueprintFormComponents.less";
import "@mil/blueprint-dialogs/lib/src/assets/less/passwordStrengthInput.less";
import "@mil/blueprint-dialogs/lib/src/assets/less/loginDialog.less";

import "~/assets/css/app.less";

// Get the root container where the app will be rendered
const rootElement = document.getElementById("react-root-container");
if (rootElement === null) {
    throw new Error(`The root element can not be null`);
}
const root = createRoot(rootElement);

function isBrowserSupported(): boolean {
    const browser = detect();
    if (!isNil(browser) && !isNil(browser.name)) {
        const name = browser.name;
        if (name === "chrome") {
            return true;
        } else if (name === "firefox") {
            return true;
        } else if (name === "edge") {
            return true;
        } else if (name === "edge-chromium") {
            // Microsoft Edge on Mac
            return true;
        }
    }
    return false;
}

if (isBrowserSupported()) {
    // Create a promise that will eventually load the main application component
    const loadingPromise = import(/* webpackChunkName: "app" */ "./app");

    root.render(<SplashScreen promise={loadingPromise} />);

    // Render the splash screen first; when it is done, start rendering the
    // main app, which is imported dynamically so Webpack can optimize it into
    // its own chunk
    loadingPromise.then(({ default: App }) => {
        root.render(<App />);
    });
} else {
    root.render(<UnsupportedBrowser />);
}
